import "./App.css";
import Logo from "./assets/image/logo2.png";
import FourDots from "./assets/image/4Dots.png";
import LeftArrow from "./assets/image/Arrow-03.png";
import RightArrow from "./assets/image/Arrow-04.png";
import ImageCraousel from "./components/imageCraousel";
import FacebookLogo from "./assets/image/Facebook-Icon.png";
import PlayStore from "./assets/image/google_play_original.png";
import AppStore from "./assets/image/apple_original.png";
import ReactHlsPlayer from "react-hls-player/dist";
import React, { useState } from "react";

function App() {
  const playerRef = React.useRef();
  const playerRef1 = React.useRef();
  const [isPlaying, setIsPlaying] = useState(true);

  function playVideo() {
    playerRef.current.play();
    playerRef1.current.play();
    setIsPlaying(true);
  }

  function pauseVideo() {
    playerRef.current.pause();
    playerRef1.current.pause();
    setIsPlaying(false);
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row bg1-primary" style={{ minHeight: "80px" }}>
          <div className="text-center d-block my-auto mx-auto ">
            {/* <img
              src={Logo}
              alt="logo"
              style={{ minHeight: "calc(100% - 41px)" }}
              width="200px"
            /> */}
            <img src={Logo} alt="logo" height="36px" width="170px" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container d-none d-md-block">
          <div className="row mb-2" style={{ marginTop: "6rem" }}>
            <div className="text-center ">
              <h1 className="this-website mt-lg-4">
                This website is under construction.
              </h1>
              <p className="this-website-para">
                Ourenglish.com will be an international platform of our
                Institute (MELI) to
                <br /> showcase its expertise and accomplishments gained in 20
                practical years of
                <br />
                experience in teaching the English language. The website will be
                a
                <br />
                representation of our thoughts, styles, approaches, designs and
                creativities.
              </p>
              <span className="this-website-para">
                Stay tuned for the launch of ourenglish.com.{" "}
              </span>
              <div className="mt-4 mb-5">
                <img src={FourDots} alt="" width="90px" />
              </div>
            </div>
          </div>
        </div>
        <div className="container d-block d-lg-none d-md-none">
          <div className="row mt-5 ">
            <div className="text-center ">
              <h1 className="this-website mt-lg-4">
                This website <br /> is under <br />
                construction.
              </h1>
              <p className="this-website-para">
                Ourenglish.com will be an
                <br />
                international platform of our Institute
                <br />
                (MELI) to showcase its expertise and
                <br />
                accomplishments gained in 20
                <br />
                practical years of experience in
                <br />
                teaching the English language. The
                <br />
                website will be a representation of our
                <br />
                thoughts, styles, approaches, designs
                <br />
                and creativities.
              </p>
              <span className="this-website-para">
                Stay tuned for the launch of ourenglish.com.{" "}
              </span>
              <div className="mt-4 mb-5">
                <img src={FourDots} alt="" width="90px" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-lg-5 video-div d-none d-md-block">
          <ReactHlsPlayer
            playerRef={playerRef}
            muted={true}
            className="video-content sample_video p-0"
            src={
              "https://s3.ap-south-1.amazonaws.com/ourenglish.com/videos/videonew.m3u8"
            }
            autoPlay={true}
          />
          ,
          {/* <iframe
            className="video-content sample_video p-0"
            data-src="https://player.vimeo.com/video/850817787?autoplay=1&loop=1&autopause=0&api=1&controls=0&muted=1?playsinline=0"
            // width="640"
            // height="300"
            frameborder="0"
            allow="autoplay;fullscreen"
            allowfullscreen
            muted
            playsinline
          ></iframe> */}
          {/* <iframe
            className="video-content sample_video p-0"
            data-src="https://player.vimeo.com/video/850817787?autoplay=1&loop=1&autopause=0&api=1&controls=0&muted=1?playsinline=0"
            // width="640"
            // height="300"
            frameborder="0"
            allow="autoplay;fullscreen"
            allowfullscreen
            muted
            playsinline
          ></iframe> */}
          {/* <video playsinline autoplay muted loop controls={false}>
            <source
              src={"https://player.vimeo.com/video/850817787"}
              type="video/mp4"
            />
          </video> */}
          <div>
            {isPlaying ? (
              <button
                className={isPlaying ? "play-btn" : "pause-btn"}
                onClick={pauseVideo}
                id=""
              >
                {" "}
                Pause
                <span className="ms-2">
                  {" "}
                  <i className="fa-solid fa-circle-pause"></i>
                </span>
              </button>
            ) : (
              <button
                className={isPlaying ? "play-btn" : "pause-btn"}
                onClick={playVideo}
                id=""
              >
                {" "}
                Play
                <span className="ms-2">
                  {" "}
                  <i className="fa-solid fa-circle-play"></i>
                </span>
              </button>
            )}
          </div>
        </div>
        <div className="row mt-lg-5  video-div d-block d-lg-none d-md-none">
          {/* <video playsinline autoplay muted loop controls={false}>
            <source
              src={"https://player.vimeo.com/video/850817787"}
              type="video/mp4"
            />
          </video> */}
          {/* <iframe
            width="100%"
            height="550"
            src="https://player.vimeo.com/video/850817787?autoplay=1&loop=1&autopause=0&api=1&controls=0&muted=1?playsinline=0"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen=""
          ></iframe> */}
          {/* <iframe
            className="video-content sample_video p-0"
            src="https://player.vimeo.com/video/850817787?autoplay=1&loop=1&autopause=0&api=1&controls=0&muted=1?playsinline=0"
            // width="640"
            // height="300"
            frameborder="0"
            allow="autoplay;fullscreen"
            allowfullscreen
            muted
            playsinline
          ></iframe> */}
          <ReactHlsPlayer
            playerRef={playerRef1}
            className="video-content sample_video p-0"
            muted={true}
            playsInline
            src={
              "https://s3.ap-south-1.amazonaws.com/ourenglish.com/videos/videonew.m3u8"
            }
            autoPlay={true}
          />
          <div>
            <button className="play-btn pause_btn" onClick={pauseVideo} id="">
              {" "}
              Pause
              <span className="ms-2">
                {" "}
                <i className="fa-solid fa-circle-pause"></i>
              </span>
            </button>

            <button
              className="play-btn play_btn"
              onClick={playVideo}
              id=""
              style={{ display: "none" }}
            >
              {" "}
              Play
              <span className="ms-2">
                {" "}
                <i className="fa-solid fa-circle-play"></i>
              </span>
            </button>
          </div>
        </div>
        <ImageCraousel />
        <div className="container">
          <div className="row mt-md-5 mb-2 mt-4">
            <div className="d-flex justify-content-center">
              <h1 className="what-is-meli text-center d-block mb-0">
                <mark className="text-bg"> What is MELI?</mark>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row mt-0">
          {/* <!-- We & Our Inspiring story Desktop system start  --> */}
          <div className="d-none d-md-block">
            <div className="text-center">
              <h1 className="this-website">We & Our</h1>
            </div>
            <div className="text-center">
              <h1 className="this-website">Inspiring Story</h1>
            </div>
          </div>
          {/* <!-- We & Our Inspiring story Desktop system start -->
            <!-- We & Our Inspiring story Mobile View Start  --> */}
          <div className="d-block d-lg-none d-md-none">
            <div className="text-center">
              <h1 className="this-website">We & Our</h1>
            </div>
            <div className="text-center">
              <h1 className="this-website">Inspiring </h1>
            </div>
            <div className="text-center">
              <h1 className="this-website">Story</h1>
            </div>
          </div>
          {/* <!-- We & Our Inspiring story Mobile View End -->
            <!-- carousal -->
            <!-- desktop system  Start--> */}
          <div className="mt-2  d-none d-md-block">
            <div
              id="carouselExampleDark"
              className="carousel carousel-dark slide"
              data-bs-ride="carousel"
              data-interval="30000"
            >
              <div className="carousel-indicators mb-0">
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide-to="0"
                  className="active carousel-indicators-btn"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                  className="carousel-indicators-btn"
                  style={{ marginLeft: "10px" }}
                >
                  {" "}
                </button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide-to="2"
                  aria-label="Slide 3 "
                  className="carousel-indicators-btn"
                  style={{ marginLeft: "10px" }}
                ></button>
              </div>
              <div className="carousel-inner p-0 mb-">
                <div
                  className="carousel-item active carousel-item-para"
                  data-bs-interval="30000"
                >
                  {/* <!-- <img src="..." className="d-block w-100" alt="..."> --> */}
                  <p className="this-website-para px-0 text-center">
                    <mark className="text-bg fw-bold"> About MELI:</mark>
                    Thriving in the industry for nineteen years, our team
                    consists of
                    <br />
                    over five hundred employees who possess high level of
                    professional expertise
                    <br />
                    and strive to specialize in a single field; teaching
                    English. Our vision is to enable
                    <br />
                    a maximum number of people to speak the international
                    language, the <br />
                    international way. As English is the lingua franca of
                    today’s world, we take a<br />
                    great pride in teaching it.
                  </p>
                  <br />
                  <div className="carousel-caption d-md-block py-2 px-0">
                    {/* <!-- <p className="this-website-para px-0 w-100"><span className="text-bg"> About MELI: </span> Thriving in
                                    the industry for nineteen
                                    years, our team consists of<br/>
                                    over five hundred employees who possess high level of professional expertise<br/>
                                    and strive to specialize in a single field; teaching English. Our vision is to
                                    enable<br/>
                                    a maximum number of people to speak the international language, the <br/>
                                    international way. As English is the lingua franca of today’s world, we take a<br/>
                                    great pride in teaching it.</p> --> */}
                  </div>
                </div>
                <div
                  className="carousel-item carousel-item-para"
                  data-bs-interval="30000"
                >
                  {/* <!-- <img src="..." className="d-block w-100" alt="..."> --> */}
                  <p className="this-website-para px-0 text-center">
                    <mark className="text-bg  fw-bold"> Today MELI:</mark>
                    Across our 10 branches, we host tens of thousands of
                    students!
                    <br />
                    Within our 250 classrooms, MELI offers up to 240 new
                    classesin a single week <br /> under the supervision of
                    hundreds of English language specialists.
                  </p>
                  <br />
                  <div className="carousel-caption  d-md-block py-2 px-0">
                    {/* <!-- <p className="this-website-para px-0 w-100"><span className="text-bg"> About MELI: </span> Thriving in
                                    the industry for nineteen
                                    years, our team consists of<br/>
                                    over five hundred employees who possess high level of professional expertise<br/>
                                    and strive to specialize in a single field; teaching English. Our vision is to
                                    enable<br/>
                                    a maximum number of people to speak the international language, the <br/>
                                    international way. As English is the lingua franca of today’s world, we take a<br/>
                                    great pride in teaching it.</p> --> */}
                  </div>
                </div>
                <div className="carousel-item carousel-item-para carousel-item-para-third">
                  {/* <!-- <img src="..." className="d-block w-100" alt="..."> --> */}
                  <p className="this-website-para px-0 text-center Tomorrow-para">
                    <mark className="text-bg fw-bold">Tomorrow’s MELI:</mark>
                    A Significant international project of MELI, ourenglish.com,
                    <br />
                    aspires to be the largest online English language platform
                    in the world
                  </p>

                  <p className="this-website-para2 text-center mb-0 Tomorrow-para">
                    {" "}
                    In this enormous endeavor,resources from the USA, India, and
                    UAE are directly
                    <br />
                    being put into practice to develop hundred of specialized
                    English language courses.
                  </p>

                  <p className="this-website-para2 px-0 text-center ">
                    Stay tuned for the launch of ourenglish.com.
                  </p>
                  <br />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide="prev"
              >
                <div className="">
                  {/* <!-- <span className="carousel-control-prev-icon" aria-hidden="true"></span> --> */}
                  <span>
                    <img
                      src={LeftArrow}
                      alt=""
                      height="50px"
                      width="50px"
                      style={{ marginRight: "20px", marginBottom: "40px" }}
                    />
                  </span>
                </div>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide="next"
              >
                {/* <!-- <span className="carousel-control-next-icon" aria-hidden="true"></span> --> */}
                <span>
                  <img
                    src={RightArrow}
                    alt=""
                    height="50px"
                    width="50px"
                    style={{ marginLeft: "20px", marginBottom: "40px" }}
                  />
                </span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          {/* <!-- desktop system  End-->
            <!-- mobile-view --> */}
          <div className="mt-2  d-block d-lg-none d-md-none">
            <div
              id="carouselExampleDark1"
              className="carousel carousel-dark slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators mb-0">
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark1"
                  data-bs-slide-to="0"
                  className="active carousel-indicators-btn"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark1"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                  className="carousel-indicators-btn"
                  style={{ marginLeft: "10px" }}
                >
                  {" "}
                </button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark1"
                  data-bs-slide-to="2"
                  aria-label="Slide 3 "
                  className="carousel-indicators-btn"
                  style={{ marginLeft: "10px" }}
                ></button>
              </div>
              <div className="carousel-inner p-0 ">
                <div
                  className="carousel-item active carousel-item-para"
                  data-bs-interval="30000"
                >
                  {/* <!-- <img src="..." className="d-block w-100" alt="..."> --> */}
                  <p className="this-website-para px-0 text-center">
                    <span className="text-bg fw-bold"> About MELI:</span>
                    Thriving in the
                    <br /> industry for nineteen years, our <br />
                    team consists of over five hundred <br />
                    employees who possess high level
                    <br /> of professional expertise and strive <br />
                    to specialize in a single field;
                    <br /> teaching English. Our vision is to
                    <br /> enable a maximum number of <br />
                    people to speak the international <br />
                    language, the international way.
                    <br /> As English is the lingua franca of <br />
                    today’s world, we take a great
                    <br /> pride in teaching it.
                  </p>
                  <br />
                  <div className="carousel-caption d-md-block py-2 px-0">
                    {/* <!-- <p className="this-website-para px-0 w-100"><span className="text-bg"> About MELI: </span> Thriving in
                                    the industry for nineteen
                                    years, our team consists of<br/>
                                    over five hundred employees who possess high level of professional expertise<br/>
                                    and strive to specialize in a single field; teaching English. Our vision is to
                                    enable<br/>
                                    a maximum number of people to speak the international language, the <br/>
                                    international way. As English is the lingua franca of today’s world, we take a<br/>
                                    great pride in teaching it.</p> --> */}
                  </div>
                </div>
                <div
                  className="carousel-item carousel-item-para"
                  data-bs-interval="30000"
                >
                  {/* <!-- <img src="..." className="d-block w-100" alt="..."> --> */}
                  <p className="this-website-para px-0 text-center">
                    <span className="text-bg fw-bold"> Today MELI:</span>
                    Across our 10 <br />
                    branches, we host tens of <br />
                    thousands of students! Within <br />
                    our 250 classrooms, MELI <br />
                    offers up to 240 new classes in <br /> a single week under
                    the <br />
                    supervision of hundreds of <br /> English language
                    specialists.
                  </p>
                  <br />
                  <div className="carousel-caption  d-md-block py-2 px-0">
                    {/* <!-- <p className="this-website-para px-0 w-100"><span className="text-bg"> About MELI: </span> Thriving in
                                    the industry for nineteen
                                    years, our team consists of<br/>
                                    over five hundred employees who possess high level of professional expertise<br/>
                                    and strive to specialize in a single field; teaching English. Our vision is to
                                    enable<br/>
                                    a maximum number of people to speak the international language, the <br/>
                                    international way. As English is the lingua franca of today’s world, we take a<br/>
                                    great pride in teaching it.</p> --> */}
                  </div>
                </div>
                <div className="carousel-item carousel-item-para carousel-item-para-third ">
                  {/* <!-- <img src="..." className="d-block w-100" alt="..."> --> */}
                  <div className="px-4">
                    <p className="this-website-para">
                      <p className=" this-website-para text-center Px-5">
                        <span className="text-bg fw-bold">
                          {" "}
                          Tomorrow’s MELI:
                        </span>
                        A Significant <br />
                        international project of MELI,
                        <br />
                        ourenglish.com,aspires to be the
                        <br />
                        largest online English language <br /> learning platform
                        in the world.
                      </p>
                      <p className="this-website-para2  text-center w-100">
                        {" "}
                        In this enormous endeavor, <br /> resources from the
                        USA, India, <br /> and UAE are directly being put <br />{" "}
                        into practice to develop <br /> hundred of specialized
                        English <br /> language courses.
                      </p>

                      <p className="this-website-para2 text-center w-100">
                        Stay tuned for the launch of <br /> ourenglish.com.
                      </p>
                    </p>
                    <br />
                  </div>

                  <div className="carousel-caption  d-md-block py-2 px-0">
                    {/* <!-- <p className="this-website-para px-0 w-100"><span className="text-bg"> About MELI: </span> Thriving in
                                    the industry for nineteen
                                    years, our team consists of
                                    over five hundred employees who possess high level of professional expertise
                                    and strive to specialize in a single field; teaching English. Our vision is to
                                    enable
                                    a maximum number of people to speak the international language, the
                                    international way. As English is the lingua franca of today’s world, we take a
                                    great pride in teaching it.</p> --> */}
                  </div>
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleDark1"
                data-bs-slide="prev"
              >
                <div className="">
                  {/* <!-- <span className="carousel-control-prev-icon" aria-hidden="true"></span> --> */}
                  <span>
                    <img
                      src={LeftArrow}
                      alt=""
                      height="50px"
                      width="50px"
                      style={{ marginRight: "20px" }}
                    />
                  </span>
                </div>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleDark1"
                data-bs-slide="next"
              >
                {/* <!-- <span className="carousel-control-next-icon" aria-hidden="true"></span> --> */}
                <span>
                  <img
                    src={RightArrow}
                    alt=""
                    height="50px"
                    width="50px"
                    style={{ marginLeft: "20px" }}
                  />
                </span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <footer className="pt-5">
        <div className="flexcontainer mt-lg-5 mt-3">
          <div className="heading3">ourenglish.com is under construction</div>
          <div className="second">
            <a
              href="https://www.facebook.com/OurMuslimInstitute/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white text-decoration-none"
            >
              <img src={FacebookLogo} alt="" height="21px " />
              {/* <!-- <span className=" text-white">   <i className="fa-brands fa-facebook"></i> </span> --> */}
              Our MELI{" "}
            </a>
          </div>
          <div className="heading1"> Download Our MELI App</div>
          <a
            href="https://meliapp.page.link/store"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "span" }}
          >
            <div style={{ marginTop: "20px" }}>
              <img
                src={PlayStore}
                className="appStoreLogo"
                alt="appStoreLogo"
                style={{ marginRight: "5px" }}
              />
              {/* <!-- <a href="https://meliapp.page.link/store"> --> */}
              <img
                src={AppStore}
                className="appStoreLogo"
                alt="PlaystoreLogo"
              />
              {/* <!-- </a> --> */}
            </div>
          </a>
          <div className="third">Our English LLC, USA</div>
          <div className="fourth">
            <img src={FourDots} alt="" />
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
